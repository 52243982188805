import { Helmet } from "react-helmet-async";
import useScreenSize from "../hooks/useScreenSize";
import ReactGA from 'react-ga4';
import { Row, Col, Image } from "react-bootstrap";
import Carousel from '../components/CarouselHostesses';
import { NavLink } from "react-router-dom";
import telroseFrance from '../img/telrose_France.webp';
import telrosePrivé from '../img/telrose_privé.webp';
import telroseAudiotel from '../img/forfait_telrose.webp';
import screenshotAllodial from '../img/screenshot-allodial.webp';
import lienMaitressesDominatrices from '../img/Lienmaitressesdominatrices.webp'
import lienRoseTelephone from '../img/Lienrosetelephone12.webp'
import telroseVisioLien from '../img/telrose-visio-lien.webp'

function Home({ sva }) {

  const { width } = useScreenSize();

  function handleClick({ category, action }) {
    ReactGA.event({
      category: category,
      action: action
    });
  }

  return (
    <>
      <Helmet>
        <title>Bienvenue sur télrose-duo.fr, appel direct au 0895900400</title>
        <meta name="description"
          content="Explorez votre sensualité avec notre service de téléphone rose. Appels directs, duos privés, et forfaits audiotel disponibles pour des moments intenses et discrets." />
        <link rel="canonical" href="https://telrose-duo.fr" />
      </Helmet>
      <h1 className="text-center mb-5" >Bienvenue sur <span className="neon-home ">télrose-duo.fr</span></h1>
      <section className="section bg-black p-4 rounded-5 mb-5">
        <Row>
          <Col lg={4} className="mb-4 mb-md-3">
            <article className="px-2">
              <h2 className="neon pb-3 text-center">Appel Direct</h2>
              {width < 576 ?
                <a href={`tel:${sva.tel}`} onClick={() => handleClick({ category: `sva ${sva.name}- Page d'accueil`, action: "Click to Call" })}>
                  <Image className="mx-auto d-block mb-3" src={sva.img} width={width < 528 ? 220 : 260} rounded alt="sva" />
                </a>
                :
                <Image className="mx-auto d-block mb-3" src={sva.img} width={width < 528 ? 220 : 260} rounded alt="sva" />
              }
              <p className="mb-4 text-center">Plongez dans un monde de plaisir sans limite avec nos appels directs de téléphone rose, facturés discrètement sur votre abonnement téléphonique. Pas de carte de crédit nécessaire, pas de transaction compliquée.</p>
              <div className="text-center">
                {width < 576 ?
                  <a className="link-home p-2" href={`tel:${sva.tel}`} onClick={() => handleClick({ category: `Bouton Appeler ${sva.name} - Page d'accueil`, action: "Click to Call" })}>Appeler</a>
                  :
                  null
                }
              </div>
            </article>
          </Col>
          {width < 768 && <hr />}
          <Col lg={4} className="mb-4 mb-md-3">
            <article className="px-2">
              <h2 className="neon pb-3 text-center">Duo privé</h2>
              {width < 576 ?
                <a href="tel:0178967730" onClick={() => handleClick({ category: "sva Duo-privé - Page d'accueil", action: "Click to Call" })}>
                  <Image className="mx-auto d-block mb-3" src={telrosePrivé} width={width < 528 ? 220 : 260} rounded alt="sva" />
                </a>
                :
                <Image className="mx-auto d-block mb-3" src={telrosePrivé} width={width < 528 ? 220 : 260} rounded alt="sva" />
              }
              <p className="mb-4 text-center">Dans notre monde de conversation privée, laissez-vous emporter par un océan de désirs inexprimés. Nos hôtesses sont là, prêtes à vous emmener dans un voyage sensuel où vos fantasmes les plus profonds deviennent réalité.</p>
              <div className="text-center">
                <NavLink className="link-home p-2" to={"/duo-prive"} caseSensitive end>Duo privé</NavLink>
              </div>
            </article>
          </Col>
          {width < 768 && <hr />}
          <Col lg={4} className="mb-2">
            <article className="px-2">
              <h2 className="neon pb-3 text-center">Forfaits Audiotel</h2>
              {width < 576 ?
                <a href="tel:0170979055" onClick={() => handleClick({ category: "sva Audiotel - Page d'accueil", action: "Click to Call" })}>
                  <Image className="mx-auto d-block mb-3" src={telroseAudiotel} width={width < 528 ? 220 : 260} rounded alt="sva" />
                </a>
                : <Image className="mx-auto d-block mb-3" src={telroseAudiotel} width={width < 528 ? 220 : 260} rounded alt="sva" />
              }
              <p className="mb-4 text-center">Découvrez nos forfaits de téléphone rose et plongez dans un univers de plaisir sans limites. Profitez de tarifs avantageux et de moments inoubliables avec nos hôtesses expérimentées, prêtes à vous guider vers des sommets de satisfaction.</p>
              <div className="text-center">
                <NavLink className="link-home p-2" to={"/audiotel"} caseSensitive end>Audiotel</NavLink>
              </div>
            </article>
          </Col>
        </Row>
        <hr />
        <article className="pb-3 text-center">
          <h2 className="neon pb-3">Hôtesses</h2>
          <Carousel />
          <NavLink className="link-home p-2" to={"/hotesses"} caseSensitive end>Hôtesses</NavLink>
        </article>
        <hr />
        <h2 className="text-center mt-3 mb-5 mt-lg-5 py-3 py-md-4 title-red-home fw-bold">Liens sites web tél rose pas cher francais</h2>
        <article className="mb-5">
          <Row>
            <Col xs={12} md={6} className="order-2 order-md-1 px-3 px-md-0 text-center">
              <a href="https://rosetelephone.fr" target="_blank" rel="noreferrer">
                <Image width={660} height={360} src={lienRoseTelephone} fluid alt="Télrose pas cher" />
              </a>
            </Col>
            <Col md={6} className="d-flex flex-column justify-content-center text-center text-md-start order-1 order-md-2">
              <h2 className="fw-bold mb-4 display-5 neon">Télrose pas cher</h2>
              <p className="fw-normal">👅 <strong>Le téléphone rose, un mythe qui ne vieillit jamais !</strong> 📞</p>
              <p>Depuis toujours, il fait vibrer ceux qui osent décrocher. Un échange coquin, une voix envoûtante et <strong>une montée de plaisir garantie !</strong></p>
              <p><strong>💃 Sensuel, osé et 100% excitant</strong></p>
              <p><strong>🔥 À tester au moins une fois… et à refaire sans hésiter !</strong></p>
              <p>Ne résiste pas, <strong>appelle et laisse-toi emporter !</strong> 😏</p>
            </Col>
            <Col md={{ span: 4, offset: 7 }} className="order-3 text-center">
              <a className="d-block mt-5" href="https://rosetelephone.fr" target="_blank" rel="noreferrer">
                <button className="button-home">Accéder au site</button>
              </a>
            </Col>
          </Row>
        </article>
        <hr />
        <article className="mt-5 mb-5">
          <Row>
            <Col className="d-flex flex-column mb-2 justify-content-center text-center text-md-start">
              <h2 className="fw-bold mb-4 display-5 neon">Maîtresses dominatrices tél rose</h2>
              <p className="fw-normal"><strong>😈 Tu n’es qu’un jouet entre ses mains… et tu adores ça. 😈</strong></p>
              <p>Laisse-toi guider par une dominatrice qui <strong>ne connaît aucune limite</strong> et qui saura te faire <strong>gémir de plaisir… ou de douleur.</strong>  Ton envie de soumission n’a jamais été aussi réelle.</p>
              <p><strong>👠 Humiliation, fétichisme, punition… à toi de choisir</strong></p>
              <p><strong>👠 Des maîtresses exigeantes et expertes</strong></p>
              <p><strong>👠 Un appel où tu n’auras qu’un seul rôle : obéir.</strong></p>
              <p>Ne réfléchis plus. <strong>Appelle et subis.</strong> 😏📞</p>
            </Col>
            <Col xs={12} md={6} className="text-center px-3 px-md-0 text-center">
              <a href="https://maitresses-telephone-rose.com" target="_blank" rel="noreferrer">
                <Image width={660} height={360} src={lienMaitressesDominatrices} fluid alt="Maitresse dominatrice téléphone rose" />
              </a>
            </Col>
            <Col md={{ span: 2, offset: 2 }} className="order-3 text-center">
              <a className="d-block mt-5" href="https://maitresses-telephone-rose.com" target="_blank" rel="noreferrer">
                <button className="button-home">Accéder au site</button>
              </a>
            </Col>
          </Row>
        </article>
        <hr />
        <article className="mb-5 mt-5">
          <Row>
            <Col xs={12} md={6} className="order-2 order-md-1 px-3 px-md-0 text-center">
              <a href="https://telrose-visio.fr" target="_blank" rel="noreferrer">
                <Image width={660} height={360} src={telroseVisioLien} fluid alt="Tél rose visio" />
              </a>
            </Col>
            <Col md={6} className="d-flex flex-column justify-content-center text-center text-md-start order-1 order-md-2">
              <h2 className="fw-bold mb-4 display-5 neon">Tél rose visio</h2>
              <p>Tu veux du <strong>plaisir en live ? 😈</strong></p>
              <p className="fw-normal">Dès que tu appelles, <strong>une hôtesse sexy t’accueille en Webcam</strong>, prête à réaliser tous tes désirs. Sensuelle, joueuse et sans tabous, elle t’offre un moment unique, rien que pour toi… <strong>100% confidentiel.</strong></p>
              <p>💋 <strong>Une connexion directe avec des filles ultra sexy</strong></p>
              <p>💋 <strong>Un show sur mesure, selon tes envies</strong></p>
              <p>💋 <strong>Zéro limite, juste du plaisir…</strong></p>
              <p>📞 <strong>Décroche et entre dans le jeu !</strong> 🔥</p>
            </Col>
            <Col md={{ span: 4, offset: 7 }} className="order-3 text-center">
              <a className="d-block mt-5" href="https://telrose-visio.fr" target="_blank" rel="noreferrer">
                <button className="button-home">Accéder au site</button>
              </a>
            </Col>
          </Row>
        </article>
      </section>
    </>
  )
}

export default Home;